import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['Python (2/3)', 'Bash', 'PowerShell', 'Terraform (OSS, Enterprise)', 'JavaScript (Node.js, React)', 'AWS', 'Azure', 'GCP', 'Java (Spring Boot)', 'Go', 'Jenkins', 'Concourse', 'OpenAI API', 'Docker', 'gatsby', 'Snowflake', 'Databricks', 'SQL', 'Kubernetes', 'HashiCorp Vault', 'HashiCorp Consul', 'Git', 'GitHub', 'Bitbucket', 'Apache Kafka', 'Selenium', 'Large Language Models (ChatGPT, Claude, Gemini, DeepSeek)'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
            I'm John, and I build software that makes a difference. My journey
            with programming kicked off when I took a computer science class in
            high school. I didn't just show up - I dominated, landing in the top
            5% of students both years. That lit a fire in me, and I've been
            pushing the limits in software engineering ever since
            </p>

            <p>
              Fast-forward to today, and I've had the privilege of working for{' '}
              <a href="https://www.lockheedmartin.com/en-us/index.html">an aerospace company</a>,{' '}
              <a href="https://www.capitalone.com/">a bank</a>,{' '}
              <a href="https://www.duke-energy.com/home">an energy company</a>, and {' '}
              <a href="https://pratt-solutions.com">myself</a>. These days, I’m all
              about building rock-solid, scalable, and secure solutions as a
              freelance software developer and consultant, while I hunt down my
              next long-term contract or W2 role. No excuses, just results.
            </p>

            <p>Here are a few technologies I've been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
